<header fxLayout="column" fxLayoutAlign="start center">

  <div class="header-top" fxLayout="column" fxLayoutAlign="center canter">
    <ol class="header-contact-container" fxLayout="row wrap"  fxLayoutAlign="center center">
      <li fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="center center">
        <img src="/assets/img/icons/phone-call.svg" alt="telefon-kontakt">
        <div class="header-contact-text-container" fxLayout="row wrap" fxLayoutGap="4px" fxLayoutAlign="center center" onclick="window.open('tel:0605264638');">
          <p>Kontakt telefoniczny:</p>
          <p>0 605 264 638</p>
        </div>
      </li>
      <li fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="center center">
        <img src="/assets/img/icons/mail.svg" alt="mail-kontakt">
        <div class="header-contact-text-container" fxLayout="row wrap" fxLayoutGap="4px" fxLayoutAlign="center center">
          <p>Kontakt e-mail:</p>
          <p>imprezy@dart-pol.pl</p>
        </div>
      </li>
    </ol>
    <div class="header-img-logo-container" fxLayout="row" fxLayoutAlign="center center">
      <img class="header-img-logo" [src]="headerTopImgSrcActived" alt="piknik-online-dart-pol-logo">
    </div>
  </div>
  <div id="menuContainerId">
    <div id="menuBoxId">
      <ng-container *ngIf="access.logged; else elseTemplate">
        <nav class="navLogged">
          <ul class="site-boxed" fxLayout="row wrap" fxLayoutAlign="center center">
            <li *ngFor="let itemLink of routesLinksActived" class="linkLi"><a [routerLink]="itemLink.link" routerLinkActive="active" class="link">{{itemLink.text}}</a></li>
          </ul>
          <div class="login-bar" fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="center center">
            <p>Dostęp aktywny "hasłem":</p>
            <span class="login-name">{{login}}</span>
            <button (click)="onLogout()">Wyloguj</button>
          </div>
        </nav>
      </ng-container>
      <ng-template #elseTemplate>
        <nav class="navLogout" fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="center center">
          <p>Uzyskaj dostęp do Pikniku Online, kliknij w</p>
          <button (click)="onToggleShowLoginPanel()">Uzyskaj dostęp</button>
        </nav>
      </ng-template>
      <div *ngIf="showLoginPanel" class="site-boxed login-panel">
        <h1>Uzyskaj dostęp: "Wpisz hasło"</h1>
        <form fxLayout="column" fxLayoutAlign="flex-start center" fxLayoutGap="15px" [formGroup]="dataFormAccess" (ngSubmit)="onSubmitAccess()">
          <div class="input-container">
            <input type="text" class="label-float" formControlName="login" required>
            <label class="floating">Wpisz hasło dostępu</label>
          </div>
          <button class="btnMain1" *ngIf="!access.isLoading" type="submit" [disabled]="dataFormAccess.invalid">Wyślij - zaloguj</button>
          <p *ngIf="access.successError" style="color: #ea1313;">Niepoprawne hasło</p>
        </form>
        <div class="bar"></div>
      </div>
    </div>
  </div>
</header>


<!-- <div class="interface-mobile">
  <div class="interface-mobile-menu-toggle" (click)="onToggleMainMenu()">
    <div id="toggle-menu-icon">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
  <div class="interface-mobile-page-up" (click)="onGoToTop()"><img src="/assets/img/icons/right-arrow.svg" alt="ikonka-up"></div>
</div> -->
