<section class="main-section-jsw20" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
  <h1>Instrukcja</h1>
  <p class="subtile">Instrukcja sparowania głośnika z telefonem w kilku krokach</p>

  <div *ngFor="let item of mainTitles; let i = index" class="list-numbers-title-container" fxLayout="row wrap" fxLayoutAlign="start center" fxLayout.xs="column" fxLayoutGap="10px">
    <div fxLayout="row" fxLayoutAlign="center center" class="number"><span>{{i + 1}}</span></div>
    <div class="text-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
      <p class="text">{{item}}</p>
    </div>
  </div>

  <div class="yt-clip-cointainer" fxLayout="row wrap" fxLayoutAlign="center center">
    <iframe frameborder="0" allowfullscreen="true" src="https://www.youtube.com/embed/CFy8VUoAbtQ"></iframe>
  </div>

</section>