
<section class="jsw2021 sectionPadding" fxLayout="column" fxLayoutAlign="start center">
  <h1 class="title1">Dziękujemy za udział</h1>
  <h1 class="title2">Wersja archiwalna dostępna od 5 sierpnia</h1>
</section>


<section class="jsw2021 welcome-text" fxLayout="row wrap" fxLayoutAlign="space-between start">
  <div class="text-container" fxLayout="column" fxLayoutAlign="center start">
    <h3 class="title3">Piknik Online</h3>

    <p>{{'&emsp;'}} <b><i>Piknik Online</i></b> realizowany jest dzięki specjalnie stworzonej platformie internetowej <b><i>www.piknikonline.pl</i></b>. Za jej pośrednictwem transmitowany jest materiał wideo z zabawą oraz zapewniona jest łączność między uczestnikami wydarzenia.</p>

    <p>{{'&emsp;'}} <b><i>Piknik Online</i></b> umożliwia integrację z innymi uczestnikami wydarzenia wykorzystując środki komunikacji elektronicznej. Uczestnicy <b><i>Pikniku Online</i></b> wykorzystując swoje smartfony lub tablety mogą łączyć się między sobą, wymieniać doświadczenia, uczestniczyć w dyskusjach na czacie, kierować pozdrowienia pozostałym uczestnikom wydarzenia. Realizacja <b><i>Pikniku Online</i></b> jest zatem możliwa na łonie natury - w parku, w ogrodzie, w gronie swoich najbliższych, przy zachowaniu łączności z pozostałymi uczestnikami i organizatorem wydarzenia.</p>

    <p>{{'&emsp;'}} W ramach <b><i>Pikniku Online</i></b> zapewniamy następujące rozrywki dostępne na uruchomionych przez nas kanałach wideo:</p>
    <ul>
      <li><b>1)</b> dwa dostępne kanały wideo relacji na żywo jednocześnie, w tym jeden z muzyką,</li>
      <li><b>2)</b> nagrany edukacyjny materiał filmowy w profesjonalnym studiu nagrań w temacie sportu i turystyki – materiał szkoleniowy z gry w szachy,</li>
      <li><b>3)</b> nagrania instruktażowe w zakresie animacji i zabaw dla dzieci.</li>
    </ul>

    <p>{{'&emsp;'}} Dla uczestników <b><i>Pikniku Online</i></b>, którzy wezmą udział w konkursach przygotowaliśmy atrakcyjne nagrody. Zachęcamy do zabawy!</p>
    
  </div>
  <div class="img-container" fxLayout="row" fxLayoutAlign="end center">
    <img src="/assets/img/home/piknik-online-main-1.jpg" alt="piknik-online-rodzina-dart-pol">
  </div>
</section>