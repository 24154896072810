<section class="main-section-jsw20" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
  <h1>Piknik online - Relacja</h1>
  <img src="/assets/img/jsw2020/logo-jsw-2020.png" alt="logo-jsw-2020">
  <p class="subtile">Piknik Online, wybierz kanał wideo relacji</p>

  <div class="chanels-container" fxLayout="row wrap" fxLayoutAlign="start center" fxLayout.xs="column" fxLayoutGap="10px">
    <div class="chanel-container">
      <div class="list-numbers-title-container-live2020" fxLayout="row wrap" fxLayoutAlign="start center" fxLayout.xs="column" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="center center" class="number"><span>1</span></div>
        <div class="text-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
          <p class="text">Kanał animacji zabaw</p>
        </div>
      </div>
      <div class="iframe-yt" fxLayout="row" fxLayoutAlign="center center">
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/j2BDhsBVbwM" frameborder="0" allowfullscreen="true"></iframe>
      </div>
    </div>
    <div class="chanel-container">
      <div class="list-numbers-title-container-live2020" fxLayout="row wrap" fxLayoutAlign="start center" fxLayout.xs="column" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="center center" class="number"><span>2</span></div>
        <div class="text-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
          <p class="text">Kanał DJ'a</p>
        </div>
      </div>
      <div class="iframe-yt" fxLayout="row" fxLayoutAlign="center center">
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/kKkX5wx7leE" frameborder="0" allowfullscreen="true"></iframe>
      </div>
    </div>
  </div>

  <h1>Filmy z animacją zabaw</h1>
  <p class="subtile">Filmy instruktażowe z animacją zabaw dla dzieci</p>
  <div class="kinder-anim-yt-cointainer" fxLayout="row wrap" fxLayoutAlign="center center">
    <div *ngFor="let item of kinderAnimYtClips" class="iframe-yt-container" fxLayout="row" fxLayoutAlign="center center">
      <iframe width="100%" height="100%" [src]="item.src | safeUrl" frameborder="0" allowfullscreen="true"></iframe>
    </div>
  </div>


  <h1>Zapraszamy na chat</h1>
  <p class="subtile">Prosimy kliknąć w prawy dolny róg czatu 'Wybierz nazwę użytkownika' oraz 'logowanie z tymczasową nazwą'</p>
  <div class="chat-cointainer" fxLayout="row wrap" fxLayoutAlign="center center">
    <iframe frameborder="0" allow="autoplay" src="https://dppiknikonline.chatango.com/"></iframe>
  </div>

  <a id="chatango-link" href="https://dppiknikonline.chatango.com/">Link do chatu</a>


  <h1>Dodatki do projektu Piknik Online</h1>
  <p class="subtile">Konkursy, gry edukacyjne, instrukcje, filmy</p>

  <!-- target="_blank" -->
  <div class="list-links-ol-box">
    <a *ngFor="let item of listProjectExtras; let i = index" [routerLink]="item.link" target="_blank" fxLayout="row" fxLayoutAlign="center center">
      <div fxLayout="row" fxLayoutAlign="center center" class="number-div"><span>{{i+1}}</span></div>
      <p  class="text">{{item.text}}</p>
    </a>
  </div>


  <h1>Filmy z origami</h1>
  <p class="subtile">Filmy instruktażowe z origami</p>
  <div class="yt-clips-50-cointainer" fxLayout="row wrap" fxLayoutAlign="center center">
    <div class="iframe-yt-container" fxLayout="row" fxLayoutAlign="center center">
      <iframe width="100%" height="100%" src="https://www.youtube.com/embed/jUbLro9GpfQ" frameborder="0" allowfullscreen="true"></iframe>
    </div>
    <div class="iframe-yt-container" fxLayout="row" fxLayoutAlign="center center">
      <iframe width="100%" height="100%" src="https://www.youtube.com/embed/jLuxdErH9fc" frameborder="0" allowfullscreen="true"></iframe>
    </div>
  </div>

  <h1>Dj Acrox</h1>
  <p class="subtile">Gwiazda Pikniku Online</p>
  <div class="yt-clips-50-cointainer" fxLayout="row wrap" fxLayoutAlign="center center">
    <div class="iframe-yt-container" fxLayout="row" fxLayoutAlign="center center">
      <iframe width="100%" height="100%" src="https://www.youtube.com/embed/iXj48kZetIo" frameborder="0" allowfullscreen="true"></iframe>
    </div>
    <div class="iframe-yt-container" fxLayout="row" fxLayoutAlign="center center">
      <iframe width="100%" height="100%" src="https://www.youtube.com/embed/9NiWz9VUQWY" frameborder="0" allowfullscreen="true"></iframe>
    </div>
  </div>


  <p id="gray-info">Przedstawione materiały stanowią własność intelektualną firmy, zabrania się kopiowania, publikowania i wykorzystywania przedstawionych treści bez zgody właściciela filmy dart-pol.</p>

</section>
