<section class="main-section-jsw20" fxLayout="column" fxLayoutAlign="start center">
  <h1>Zapowiedź Pikniku Online!</h1>
  <div class="iframe-yt" fxLayout="row" fxLayoutAlign="center center">
    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/eMaVgJ0x1Lw" frameborder="0" allowfullscreen="true"></iframe>
  </div>
  <h1>Witamy na stronie www.piknikonline.pl</h1>
  <div class="welcome-text" fxLayout="row wrap" fxLayoutAlign="center start" fxLayoutGap="25px">
    <div fxLayout="row" fxLayoutAlign="center center">
      <p>Sytuacja w okresie kwarantanny jest sytuacją bardzo stresująca zarówno dla dzieci, jak i ich rodziców. Rodzice w tym okresie muszą przejąć wiele obowiązków, wejść w rolę nauczycieli, kompanów do zabawy, zastąpić brak kontaktu z dziadkami, kuzynostwem, kolegami i koleżankami z sąsiedztwa. W tym trudnym okresie organizacja pikniku firmowego, który zgodny byłby z obowiązującymi przepisami, nie stwarzał zagrożenia epidemiologicznego staje się ważną misją odpowiedzialnego pracodawcy. Brak corocznego pikniku rodzinnego z całą pewnością pogłębiłby sytuację stresową u dzieci i ich rodziców. Wychodząc naprzeciw tej sytuacji firma Dart-Pol przygotowała specjalną ofertę – „Piknik online”, który w tym trudnym okresie pomoże rodzicom, dzieciom, ale także zintegruje pracowników z zakładem pracy. Nasz pomysł popierają psychologowie dziecięcy oraz specjaliści od komunikacji społecznej. Gwarantujemy, że organizacja przez Państwa „Pikniku online” będzie 100% sukcesem.</p>
    </div>
    <div fxLayout="row" fxLayoutAlign="center start">
      <img src="/assets/img/home/piknik-online-main-1.jpg" alt="piknik-online-rodzina-dart-pol">
    </div>
  </div>
  <h2>Akcesoria potrzebne do Pikniku Online</h2>
  <ol class="accessory-list" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="25px">
    <li fxLayout="row wrap" fxLayoutAlign="start center" fxLayout.xs="column" fxLayoutGap="10px" *ngFor="let item of accessoryList">
      <div fxLayout="row" fxLayoutAlign="center center" class="number"><span>{{item.number}}</span></div>
      <div class="text-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
        <p class="text">{{item.text}}</p>
      </div>
    </li>
  </ol>
</section>
