<section class="jsw2021 sectionPadding" fxLayout="column" fxLayoutAlign="start center">
  <h1 class="title1">Bezpieczeństwo</h1>
</section>

<section class="jsw2021" fxLayout="row wrap" fxLayoutAlign="space-between start">
  <div class="section-text" fxLayout="column" fxLayoutAlign="center start">
    <div class="text-container" fxLayout="column" fxLayoutAlign="center start">

      <div class="titlesNnumber-container-box">
        <div class="container-item" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
          <div fxLayout="row" fxLayoutAlign="center center" class="number-div"><span>1</span></div>
          <h2 class="text">Dobór lokalizacji</h2>
        </div>
      </div>

      <p>{{'&emsp;'}} Wybierając miejsce na piknik rodzinny można skorzystać z przydomowego ogródka, ale również z atrakcyjnych miejsc widokowych, jednak należy uwzględniać możliwe zagrożenia.</p>

      <div class="titlesNnumber-container-box">
        <div class="container-item" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
          <div fxLayout="row" fxLayoutAlign="center center" class="number-div"><span>2</span></div>
          <h2 class="text">Zagrożennia</h2>
        </div>
      </div>

      <ul>
        <li><b>1)</b> Owady: kleszcze, osy, mrówki, komary, itp.</li>
        <li><b>2)</b> Zbiorniki wodne, niezabezpieczone studzienki, dziury, przekopy, itp.</li>
        <li><b>3)</b> Niesprawne instalacje elektryczne</li>
        <li><b>4)</b> Wysokie temperatury, przegrzanie organizmu uczestników pikniku</li>
      </ul>
  
      <p>{{'&emsp;'}} Organizując zabawę w gronie rodzinnym z dziećmi należy po wyborze miejsca sprawdzić najbliższą okolicę, wyznaczyć teren, na którym dzieci mogą się swobodnie poruszać, jednak powinno się to odbywać w zasięgu wzroku rodziców. Przed rozłożeniem koca i akcesoriów piknikowych należy teren sprawdzić.</p>

      <div class="titlesNnumber-container-box">
        <div class="container-item" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
          <div fxLayout="row" fxLayoutAlign="center center" class="number-div"><span>3</span></div>
          <h2 class="text">Porządek, higiena i zachowanie bezpieczeństwa w dobie zagrożenia koronawirusem</h2>
        </div>
      </div>

      <p>{{'&emsp;'}} Piknik rodzinny powinno się organizować w gronie domowników unikając miejsc uczęszczanych przez dużą ilość osób pamiętając o zachowaniu higieny i częstym myciu rąk. W trakcie planowania pikniku należy przygotować wodę do całkowitego wygaszenia grilla jednorazowego. Należy zaplanować miejsce złożenia popiołu oraz utylizacji śmieci.</p>

    </div>
  </div>
</section>

<section class="sectionPadding">
  <p id="gray-info">Przedstawione materiały stanowią własność intelektualną firmy, zabrania się kopiowania, publikowania i wykorzystywania przedstawionych treści bez zgody właściciela filmy dart-pol.</p>
</section>