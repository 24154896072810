import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contest-art-jsw20',
  templateUrl: './contest-art-jsw20.component.html',
  styleUrls: ['./contest-art-jsw20.component.scss']
})
export class ContestArtJsw20Component implements OnInit {

  constructor() { }
  // dodać pobranie regulaminu - pdf
  // dodać pobranie oświadczenia - pdf

  public galleryArray = [
    { src: 'brak', alt: 'brak-zdjecia', author: 'autor'},
  ];

  ngOnInit(): void {
    this.initImagesToGallery();
  }

  private initImagesToGallery() {
    // konkurs-1.jpg
    // author and number of image
    const authors = [
      { name: 'Martyna Kalus', age: 7, numberOfImg: 1},
      { name: 'Natalia Kalus', age: 12, numberOfImg: 2},
      { name: 'Alicja Olesińska', age: 5, numberOfImg: 3},
      { name: 'Daria', age: 9, numberOfImg: 4},
      { name: 'Oskar', age: 7, numberOfImg: 5},
    ];
    const newGallery = [];
    const numberOfImages = authors.length; // liczone od 1
    const startNameImge = 'konkurs-';
    const path = '/assets/img/jsw2020/contest-art';
    for (let i = 0; i < numberOfImages; i++) {
      const item = authors[i];
      const img = {
        src: `${path}/${startNameImge}${item.numberOfImg}.jpg`,
        alt: `${startNameImge}${item.numberOfImg}`,
        author: `${item.name}, lat ${item.age}`
      }
      newGallery.push(img);
    }
    this.galleryArray = newGallery;
  }


}
