<section class="main-section-jsw20" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
  <h1>Konkurs fotograficzny - galeria</h1>
  <p class="subtitle">Dla uczestników naszego pikniku przygotowaliśmy konkurs fotograficzny. Zrób zdjęcie z naszego pikniku i dołącz do niego krótki opis wydarzenia. Prześlij swoją fotografię z „Pikniku online” na adres: "<b>konkurs@dart-pol.pl</b>". Wśród najciekawszych prac - utrwalonych przez Was wspomnień z „Pikniku online” rozlosujemy nagrody.</p>

  <div class="big-buttons" fxLayout="row wrap" fxLayoutAlign="center center">
    <a class="button" href="/assets/pdf/jsw2020/regulamin-konkursu-fotograficznego.pdf" target="_blank">REGULAMIN KONKURSU FOTOGRAFICZNEGO</a>
  </div>

  <div class="gallery" fxLayout="row wrap" fxLayoutAlign="center center" fxLayout.xs="column">
    <div class="imgContainer" *ngFor="let item of galleryArray" class="imgContainer" fxLayout="row" fxLayoutAlign="center center">
      <img  [src]="item.src" [alt]="item.alt">
    </div>
  </div>

  <p id="gray-info">Przedstawione materiały stanowią własność intelektualną firmy, zabrania się kopiowania, publikowania i wykorzystywania przedstawionych treści bez zgody właściciela filmy dart-pol.</p>
</section>