<section class="jsw2021 sectionPadding" fxLayout="column" fxLayoutAlign="start center">
  <h1 class="title1">Dołączenie do pokoju Teams</h1>
</section>


<section class="main-sections-padding" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
  <h1>Instrukcja dołączenia do pokoju w apliacji "Teams" od firmy Microsoft</h1>
  <h3 class="subtitle">DOŁĄCZ DO POKOJU W APLIKACJI 'TEAMS'</h3>
  <p class="subtitle">• WYMAGANE NARZĘDZIA DO POŁĄCZENIA SIĘ POPRZEZ APLIKACJĘ TEAMS •</p>

  <p class="subtitle"><b>Darmowa</b> platforma Teams służy m.in. do utrzymywania kontaktu ze współpracownikami, prowadzenia z nimi rozmów na czacie i wideokonferencji. Zostanie utworzony kanał do wideokonferencji, który umożliwi komunikację Państwa wraz z kolegami/koleżankami z pracy. Możliwe będzie utworzenie własnych kanałów i zapraszanie wybranych kolegów/koleżanek z pracy.</p>

  <p class="subtitle">UTWORZYLIŚMY DLA PAŃSTWA POKÓJ W KTÓRYM KAŻDY Z UCZESTNIKÓW MOŻE WYSYŁAĆ WIADOMOŚCI ORAZ UCZESTNICZYĆ W WIDEOKONFERENCJI, ZEZWALAJĄC NA DOSTĘP DO KAMERY I MIKROFONU</p>

  <div class="list-titlesNnumber-container-box">
    <div class="container-item" fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start center">
      <div fxLayout="row" fxLayoutAlign="center center" class="number-div"><span>Sposób #1</span></div>
      <p  class="text">DLA OSÓB UŻYTKUJĄCYCH KOMPUTER PC, LAPTOP, TABLET, BEZ KONTA MICROSOFT TEAMS, BEZ APLIKACJI, WYMAGANA PRZEGLĄDARKA INTERNETOWA GOOGLE CHROME LUB MICROSOFT EDGE</p>
    </div>
  </div>

  <section class="tutorial-section" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
    <h1>Przeczytaj instrukcję połączenia się z <span class="noWrap">wideo-rozmową</span> krok po kroku, następnie ją wykonaj</h1>
    <p class="steps">KROK 1. KLIKNIJ W LINK DOŁĄCZ DO POKOJU</p>
    <p class="steps">KROK 2. WYBIERZ SPOSÓB DOŁĄCZENIA <b>'ZAMIAST TEGO OTWÓRZ W PRZEGLĄDARCE'</b> LUB <b>'KONTYNUUJ W TEJ PRZEGLĄDARCE'</b></p>

    <img class="img-teams-connect" src="/assets/img/jsw2020/teams/krok-2-wybor-przegladarki.jpg" alt="krok-2-wybor-przegladarki.jpg">

    <p class="steps">KROK 3. WPISZ SWOJE IMIĘ I ZEZWÓL NA DZIAŁANIE KAMERY I MIKROFONU</p>
    <img class="img-connect" src="/assets/img/jsw2020/teams/krok-3-teams-dolaczenie.jpg" alt="krok-3-teams-dolaczenie.jpg">

    <p class="steps">KROK 4. PO AKCEPTACJI AUTOMATYCZNIE DOŁĄCZY DO POKOJU</p>
    <p class="steps">---------------------------------------------------</p>
    <p class="steps">KLIKNIJ W PRZYCISK PONIŻEJ I WYKONAJ INSTRUKCJĘ W KROKACH OD 2 DO 4</p>
    <a class="btn-teams" target="_blank" href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_MzgzY2Y0ZDUtNTdlMi00MTY5LTliNWEtMGM3Y2RkOTExODYy%40thread.v2/0?context=%7b%22Tid%22%3a%22a38371eb-f097-4eb0-83ca-ed7f1dca6ec8%22%2c%22Oid%22%3a%2201c34b15-8881-4629-82c9-2d4456fc2cf1%22%7d">Dołącz do pokoju</a>

    <div class="list-titlesNnumber-container-box">
      <div class="container-item" fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start center">
        <div fxLayout="row" fxLayoutAlign="center center" class="number-div"><span>Sposób #2</span></div>
        <p  class="text">DLA OSÓB KORZYSTAJĄCYCH Z TELEFONU KOMÓRKOWEGO</p>
      </div>
    </div>

    <p class="steps center-text">!! <span class="red">WYMAGANE: KONTO MICROSOFT TEAMS, APLIKACJA TEAMS</span> !!</p>
    <img src="/assets/img/jsw2020/teams/teams-logo.jpg" alt="teams-logo.jpg">


    <p class="steps">KROK 1. KLIKNIJ W PRZYCISK PONIŻEJ I POBIERZ APLIKACJĘ, ZAINSTALUJ</p>
    <a class="btn-teams" target="_blank" href="https://play.google.com/store/apps/details?id=com.microsoft.teams">Pobierz aplikację</a>

    <p class="steps">KROK 2. KLIKNIJ W PRZYCISK PONIŻEJ I WRÓĆ NA STRONĘ WWW.PIKNIKONLINE.PL</p>
    <a routerLink="/jsw20/pokoj-teams" class="btn-teams">www.piknikonline.pl</a>

    <p class="steps">KROK 3. KLIKNIJ W PRZYCISK PONIŻEJ I DOŁĄCZ DO WIDEO-ROZMOWY</p>
    <a class="btn-teams" target="_blank" href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_MzgzY2Y0ZDUtNTdlMi00MTY5LTliNWEtMGM3Y2RkOTExODYy%40thread.v2/0?context=%7b%22Tid%22%3a%22a38371eb-f097-4eb0-83ca-ed7f1dca6ec8%22%2c%22Oid%22%3a%2201c34b15-8881-4629-82c9-2d4456fc2cf1%22%7d">Dołącz do wideo rozmowy</a>

    <p class="steps">KROK 4. PO AKCEPTACJI AUTOMATYCZNIE DOŁĄCZYSZ DO WIDEO-ROZMOWY</p>

  </section>

</section>