
<section class="jsw2021 sectionPadding" fxLayout="column" fxLayoutAlign="start center">
  <h1 class="title1">Konkurs Fotograficzny</h1>
</section>


<section class="jsw2021" fxLayout="row wrap" fxLayoutAlign="space-between start">
  <div class="section-text" fxLayout="column" fxLayoutAlign="center start">
    <div class="text-container" fxLayout="column" fxLayoutAlign="center start">
      <h3 class="title2">„JESTEM UCZESTNIKIEM PIKNIKU”</h3>
      <p>{{'&emsp;'}} Dla uczestników Pikniku Online przygotowaliśmy konkurs fotograficzny, pn. „Jestem uczestnikiem pikniku”.</p>
      <p>{{'&emsp;'}} Zrób zdjęcie z Pikniku Online i dołączyć do niego krótki opis wydarzenia. Fotografię prześlij na adres: konkurs@dart-pol.pl. W treści wiadomości mailowej należy wskazać: imię, nazwisko, numer znaczka pracownika, adres e-mail Uczestnika oraz adres miejsca, w którym w przypadku wygranej będzie zainstalowane urządzenie rekreacyjne (nagroda w konkursie).</p>
      <p>{{'&emsp;'}} Wśród najciekawszych prac - utrwalonych przez uczestników wspomnień z Pikniku Online rozlosowane będą nagrody w formie upominków. Nagrodzonych zostanie pierwsze 10 osób. Trzy pierwsze miejsca to nagrody główne w postaci voucherów na wynajem zamku do skakania na weekend lub stołu do piłkarzyków na tydzień. Pozostałe nagrody to pluszowe maskotki.</p>
    </div>
    <div class="big-buttons" fxLayout="row wrap" fxLayoutAlign="center center">
      <a class="button" href="/assets/pdf/jsw2021/regulamin-konkursu-fotograficznego.pdf" target="_blank">REGULAMIN KONKURSU FOTOGRAFICZNEGO</a>
    </div>
  </div>
</section>

<section class="jsw2021" fxLayout="row wrap" fxLayoutAlign="space-between start">
  <div class="section-text section-text-no-shadow" fxLayout="column" fxLayoutAlign="center start">
    <div class="text-container" fxLayout="column" fxLayoutAlign="center start">
      <h3 class="title2">Laureat konkursu fotograficznego:</h3>
      <h3 class="title4">Mirosław Oleś</h3>
      <p>"Jestem uczestnikiem pikniku".</p>
      <p>W szachy gramy, na hamaku się bujamy, w wodzie się pluskamy i na trampolinie fikamy. Muzy z D-jem słuchamy, paluszki przygryzamy, super gadżety mamy. Warto wiedzieć, że dzięki JSW S.A.  piknikowi on-line, wakacje tak świetnie spędzamy!</p>
    </div>
    <div class="images-constainer" fxLayout="row wrap" fxLayoutAlign="center center">
      <div class="img-container" fxLayout="row" fxLayoutAlign="center center">
        <img src="/assets/img/jsw2021/contest-photo/konkurs-fotograficzny-1.jpeg" alt="konkurs-fotograficzny-1.jpeg">
      </div>
    </div>
  </div>
</section>