import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teams-jsw20',
  templateUrl: './teams-jsw20.component.html',
  styleUrls: ['./teams-jsw20.component.scss']
})
export class TeamsJsw20Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
