<section class="main-section-jsw20" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">

  <h1>Porady</h1>
  
  <div class="list-titlesNnumber-container-box">
    <div class="container-item" fxLayout="row" fxLayoutAlign="start center">
      <div fxLayout="row" fxLayoutAlign="center center" class="number-div"><span>1</span></div>
      <p  class="text">PRAKTYCZNE PORADY</p>
    </div>
  </div>

  <p>W zestawie gadżetów piknikowych znajduje się głośnik, który należy sparować z telefonem. Umieszczenie urządzeń elektronicznych bezpośrednio na kocu, po którym rodzina się porusza może spowodować mechaniczne uszkodzenie urządzeń. W związku z tym warto zaplanować umieszczenie głośnika, telefonu, tabletu i innych urządzeń elektronicznych w bezpiecznym miejscu zabezpieczonym przed nadepnięciem przez członka rodziny i zalaniem sprzętu. Ważne by zabrać wilgotne chusteczki, najlepiej z przeznaczeniem dla niemowląt do zachowania higieny i do zmycia grafiki z twarzy dzieci po zakończeniu zabawy w malowanie twarzy. Farba do malowania twarzy trudno się zmywa pod samą wodą lub wodą z mydłem. Bardzo dobrze w tym celu sprawdzają się nawilżane chusteczki.</p>

  <p id="gray-info">Przedstawione materiały stanowią własność intelektualną firmy, zabrania się kopiowania, publikowania i wykorzystywania przedstawionych treści bez zgody właściciela filmy dart-pol.</p>
</section>