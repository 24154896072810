import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-advice-jsw20',
  templateUrl: './advice-jsw20.component.html',
  styleUrls: ['./advice-jsw20.component.scss']
})
export class AdviceJsw20Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
