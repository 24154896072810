
<section class="jsw2021 sectionPadding" fxLayout="column" fxLayoutAlign="start center">
  <h1 class="title1">Nagrody</h1>
</section>


<section class="jsw2021" fxLayout="row">
  <div class="section-text" fxLayout="column" fxLayoutAlign="end start">
    <div class="text-container" fxLayout="column" fxLayoutAlign="center start">
      <h3 class="title3">Do wygrania</h3>
      <p>{{'&emsp;'}} Biorąc aktywny udział w Pikniku Online oraz przystępując do przygotowanych przez nas konkursów uczestnicy mogą wygrać atrakcyjne nagrody.</p>
      <p>{{'&emsp;'}} Nagrodami głównymi w konkursie fotograficznym są vouchery na wynajem urządzeń rekreacyjnych: <b>wynajem zamku do skakania na weekend lub wynajem stołu do piłkarzyków na tydzień</b>. Sprzęt wynajmowany jest do Waszych domów, gdzie możecie kontynuować zabawę rozpoczętą na Pikniku Online.</p>
    </div>
    <div class="images-constainer" fxLayout="row wrap" fxLayoutAlign="space-around center">
      <div class="img-container" fxLayout="row" fxLayoutAlign="center center">
        <img src="/assets/img/jsw2021/awards/mistrzostwa-w-pilkarzyki-1.jpg" alt="mistrzostwa-w-pilkarzyki-1.jpg">
      </div>
      <div class="img-container" fxLayout="row" fxLayoutAlign="center center">
        <img src="/assets/img/jsw2021/awards/zamek-do-skakania.jpg" alt="zamek-do-skakania.jpg">
      </div>
    </div>
  </div>
</section>

<section class="sectionPadding">
  <p id="gray-info">Przedstawione materiały stanowią własność intelektualną firmy, zabrania się kopiowania, publikowania i wykorzystywania przedstawionych treści bez zgody właściciela filmy dart-pol.</p>
</section>
