<section class="main-section-jsw20" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">

  <h1>Nagrody</h1>
  <p class="subtitle">Nagrody można otrzymać biorąc aktywny udział w projekcie pikniku online i uczestnicząc w konkursie fotograficznym. Nagrodami są urządzenia rekreacyjne: zjeżdżalnia dmuchana lub zamek do skakania, które wynajmiemy do Waszych domów na jeden wybrany dzień w ramach naszego pikniku.</p>
  
  <div class="preview-awards" fxLayout="row wrap" fxLayoutAlign="center center" fxLayout.xs="column">
    <div class="container" fxLayout="column" fxLayoutAlign="start center">
      <div class="imgContainer" fxLayout="row" fxLayoutAlign="center center">
        <img  src="/assets/img/jsw2020/awards/img-1.jpg" alt="img-1.jpg">
      </div>
    </div>
    <div class="container" fxLayout="column" fxLayoutAlign="start center">
      <div class="imgContainer" fxLayout="row" fxLayoutAlign="center center">
        <img  src="/assets/img/jsw2020/awards/img-2.jpg" alt="img-2.jpg">
      </div>
    </div>
  </div>

  <p class="hand-writing-font">W imieniu całego zespołu firmy Dart-pol chciałbym bardzo podziękować za Państwa aktywny udział w zabawie w trakcie pikniku online. Szczególne podziekowania kieruję do Państwa Anny i Łukasza Jaszczyk za organizację pikniku z wykorzystaniem naszej nagrody zjeżdzalni Pirat. Czujemy się bardzo docenieni umieszczeniem naszego logotypu na chlebie, który Państwo przygotowaliście na to święto Jest to bardzo miły gest, który odbieramy jako docenienie wysiłku całego zespołu organizacyjnego w realizacji wszystkich projektów dla JSW na przestrzeni ostatnich lat. Jest nam z tego względu bardzo miło.</p>
  <br>
  <br>
  <p class="hand-writing-font">Serdecznie pozdrawiam</p>
  <br>
  <p class="hand-writing-font"><b>- Marcin Myśliwiec</b></p>

  <h1>Laureaci konkursu fotograficznego</h1>
  <p class="subtitle">W konkursie fotograficznym wszyscy uczestnicy, którzy w terminie określonym w regulaminie przesłali swoje zdjęcia z Integracyjnego Pikniku Online otrzymują nagrody:</p>


  <section class="winners-of-contest-photo-container" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
    <div class="item-winner" *ngFor="let item of arrayWinnersPhoto; let i = index">
      <p class="subtitle name"><b>{{(i+1) + '. ' + item.name}}</b></p>
      <p class="text"><i>„{{item.text}}”</i></p>
      <div class="gallery-2-col" fxLayout="row wrap" fxLayoutAlign="center center" fxLayout.xs="column">
        <div *ngFor="let itemImg of item.imagesSrc" class="container" fxLayout="column" fxLayoutAlign="start center">
          <div class="imgContainer" fxLayout="row" fxLayoutAlign="center center">
            <img  [src]="itemImg" [alt]="itemImg">
          </div>
        </div>
      </div>
      <hr>
    </div>
  </section>



  <p class="center-text"><b>Zwycięzcom gratulujemy</b></p>
  <p class="center-text"><b>Po odbiór nagród prosimy zgłosić się do Działu Socjalnego KWK „Jastrzębie-Bzie”.</b></p>




  <h1>Laureaci konkursu plastycznego</h1>
  <p class="subtitle">W konkursie plastycznym wszyscy uczestnicy, którzy w terminie określonym w regulaminie przesłali swoje prace plastyczne z Integracyjnego Pikniku Online otrzymują nagrody:</p>

  <ol class="ol-list-winners-art">
    <li *ngFor="let item of arrayWinnersArt" class="subtitle">{{item.name}}</li>
  </ol>




  <p id="gray-info">Przedstawione materiały stanowią własność intelektualną firmy, zabrania się kopiowania, publikowania i wykorzystywania przedstawionych treści bez zgody właściciela filmy dart-pol.</p>
</section>