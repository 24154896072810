import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-education-games-jsw2021',
  templateUrl: './education-games-jsw2021.component.html',
  styleUrls: ['./education-games-jsw2021.component.scss']
})
export class EducationGamesJsw2021Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
