<section class="main-section-jsw20" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
  <h1>Filmy w wirtualnej rzeczywistości</h1>
  <p class="subtitle">poniżej znajduje się kilka filmów stworzonych w wirtualnej rzeczywistości, które można uruchomić na symulatorze 3D</p>

  <div class="yt-clip-cointainer" fxLayout="row wrap" fxLayoutAlign="center center">
    <iframe frameborder="0" allowfullscreen="true" src="https://www.youtube.com/embed/rDjYa5LPn5s"></iframe>
  </div>
  <div class="yt-clip-cointainer" fxLayout="row wrap" fxLayoutAlign="center center">
    <iframe frameborder="0" allowfullscreen="true" src="https://www.youtube.com/embed/2Lh0dSRqH4E"></iframe>
  </div>
  <div class="yt-clip-cointainer" fxLayout="row wrap" fxLayoutAlign="center center">
    <iframe frameborder="0" allowfullscreen="true" src="https://www.youtube.com/embed/1voV-DLw22s"></iframe>
  </div>
  <div class="yt-clip-cointainer" fxLayout="row wrap" fxLayoutAlign="center center">
    <iframe frameborder="0" allowfullscreen="true" src="https://www.youtube.com/embed/OwfXGXFYt0s"></iframe>
  </div>

</section>