<section class="main-section-jsw20" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
  <h1>Bezpieczeństwo</h1>

  <div class="list-numbers-title-container" fxLayout="row wrap" fxLayoutAlign="start center" fxLayout.xs="column" fxLayoutGap="10px">
    <div fxLayout="row" fxLayoutAlign="center center" class="number"><span>1</span></div>
    <div class="text-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
      <p class="text">Dobór lokalizacji</p>
    </div>
  </div>
  <p>Wybierając miejsce na piknik rodzinny można skorzystać z przydomowego ogródka, ale również z atrakcyjnych miejsc widokowych, jednak należy uwzględniać możliwe zagrożenia.</p>
  <h2>Zagrożennia</h2>
  <ol class="text-main">
    <li>Owady: kleszcze, osy, mrówki, komary, itp.</li>
    <li>Zbiorniki wodne, niezabezpieczone studzienki, dziury, przekopy, itp.</li>
    <li>Niesprawne instalacje elektryczne</li>
    <li>Wysokie temperatury, przegrzanie organizmu uczestników pikniku</li>
  </ol>
  <p>Organizując zabawę w gronie rodzinnym z dziećmi należy po wyborze miejsca sprawdzić najbliższą okolicę, wyznaczyć teren, na którym dzieci mogą się swobodnie poruszać, jednak powinno się to odbywać w zasięgu wzroku rodziców. Przed rozłożeniem koca i akcesoriów piknikowych należy teren sprawdzić.</p>

  <div class="list-numbers-title-container" fxLayout="row wrap" fxLayoutAlign="start center" fxLayout.xs="column" fxLayoutGap="10px">
    <div fxLayout="row" fxLayoutAlign="center center" class="number"><span>2</span></div>
    <div class="text-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
      <p class="text">Porządek, higiena i zachowanie bezpieczeństwa w dobie zagrożenia koronawirusem</p>
    </div>
  </div>
  <p>Piknik rodzinny powinno się organizować w gronie domowników unikając miejsc uczęszczanych przez dużą ilość osób pamiętając o zachowaniu higieny i częstym myciu rąk. W trakcie planowania pikniku należy przygotować wodę do całkowitego wygaszenia grilla jednorazowego. Należy zaplanować miejsce złożenia popiołu oraz utylizacji śmieci.</p>

  <p id="gray-info">Przedstawione materiały stanowią własność intelektualną firmy, zabrania się kopiowania, publikowania i wykorzystywania przedstawionych treści bez zgody właściciela filmy dart-pol.</p>

</section>
