import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainSiteJsw20Component } from './main-site-jsw20/main-site-jsw20.component';
import { SecureJsw20Component } from './secure-jsw20/secure-jsw20.component';
import { LiveJsw20Component } from './live-jsw20/live-jsw20.component';
import { ContestPhotoJsw20Component } from './contest-photo-jsw20/contest-photo-jsw20.component';
import { ContestArtJsw20Component } from './contest-art-jsw20/contest-art-jsw20.component';
import { TeamsJsw20Component } from './teams-jsw20/teams-jsw20.component';
import { PrintingJsw20Component } from './printing-jsw20/printing-jsw20.component';
import { AwardsJsw20Component } from './awards-jsw20/awards-jsw20.component';
import { CuriositiesJsw20Component } from './curiosities-jsw20/curiosities-jsw20.component';
import { GamesJsw2020Component } from './list-project-extras/games-jsw2020/games-jsw2020.component';
import { SpeakerJsw2020Component } from './list-project-extras/speaker-jsw2020/speaker-jsw2020.component';
import { Simulator3dJsw2020Component } from './list-project-extras/simulator3d-jsw2020/simulator3d-jsw2020.component';
import { AdviceJsw20Component } from './advice-jsw20/advice-jsw20.component';

const jsw2020Routes: Routes = [
  { path: 'strona-glowna', component: MainSiteJsw20Component },
  { path: 'bezpiecznstwo', component: SecureJsw20Component },
  { path: 'relacja-na-zywo', component: LiveJsw20Component },
  { path: 'konkurs-fotograficzny', component: ContestPhotoJsw20Component },
  { path: 'konkurs-plastyczny', component: ContestArtJsw20Component },
  { path: 'pokoj-teams', component: TeamsJsw20Component },
  { path: 'poligrafia', component: PrintingJsw20Component },
  { path: 'nagrody', component: AwardsJsw20Component },
  { path: 'porady', component: AdviceJsw20Component },
  { path: 'ciekawostki', component: CuriositiesJsw20Component },

  { path: 'gry-edukacyjne', component: GamesJsw2020Component },
  { path: 'instrukcja-uruchomienia-glosnika', component: SpeakerJsw2020Component },
  { path: 'filmy-pod-symulator-3d', component: Simulator3dJsw2020Component },
];

@NgModule({
  imports: [RouterModule.forChild(jsw2020Routes)],
  exports: [RouterModule],
})
export class Jsw2020RoutingModule {}
