<footer fxLayout="column" fxLayoutAlign="start start">
  <div fxLayout="row" fxLayoutAlign="center center">
    <img src="/assets/img/logo_dp-footer.png" alt="logo-dart-pol" height="54px">
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="footer-content-container">
    <div fxLayout="column" fxLayoutAlign="start center" class="footer-contact">
      <h3>Kontakt</h3>
      <ul fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px" fxLayoutAlign.xs="center center">
        <li *ngFor="let item of footerContactData" fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.xs="center center" fxLayout.xs="column" fxLayoutGap="15px" fxLayoutGap.xs="5px">
          <img [src]="item.icon.src" [alt]="item.icon.alt">
          <div fxLayout="column" fxLayoutAlign="start start" fxLayoutAlign.xs="center center" fxLayoutGap="5px">
            <p>{{item.text.line1}}</p>
            <p>{{item.text.line2}}</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="footer-websites" fxLayout="column" fxLayoutAlign="start center">
      <h3>Nasze strony www:</h3>
      <ul>
        <li *ngFor="let item of footerWebsitesData">
          <a [href]="item.href">• {{item.text}}</a>
        </li>
      </ul>
    </div>
    <div class="footer-ms" fxLayout="column" fxLayoutAlign="start center">
      <h3>Media społecznościowe:</h3>
      <ul fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" fxLayoutAlign.xs="center center">
        <li>
          <a href="" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
            <div class="twarzksiazka" fxLayout="row" fxLayoutAlign="center center">
              <img src="/assets/img/icons/facebook-letter.svg" alt="ikonka">
            </div>
            <span>Facebook</span>
          </a>
        </li>
        <li>
          <a href="" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
            <div class="youtube" fxLayout="row" fxLayoutAlign="center center">
              <img src="/assets/img/icons/youtube.svg" alt="ikonka">
            </div>
            <span>Youtube</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="copyright">
    <p>COPYRIGHT © 2021 WWW.PIKNIKONLINE.PL</p>
  </div>
</footer>
