import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-live-jsw20',
  templateUrl: './live-jsw20.component.html',
  styleUrls: ['./live-jsw20.component.scss']
})
export class LiveJsw20Component implements OnInit {

  constructor() { }

  public kinderAnimYtClips = [
    { src: '', embedCode: '6drAFRZyMjg'},
    { src: '', embedCode: 'Stwp1r21tOE'},
    { src: '', embedCode: 'HUmNMZREUfA'},
    { src: '', embedCode: '_ZN1GOfXPNU'},
    { src: '', embedCode: 'NXq49oG4j_o'},
    { src: '', embedCode: '-7B_drjq7A8'},
    { src: '', embedCode: 'p-ASLcBWyNI'},
  ];

  public listProjectExtras = [
    { text: 'Gry edukacyjne', link: '/jsw20/gry-edukacyjne'},
    { text: 'Instrukcja uruchomienia głośnika', link: '/jsw20/instrukcja-uruchomienia-glosnika'},
    { text: 'Filmy pod symulator 3D', link: '/jsw20/filmy-pod-symulator-3d'},
  ]

  ngOnInit(): void {
    this.kinderAnimYtClips = this.kinderAnimYtClips.map( clip => {
      const clipReturn = {
        src: 'https://www.youtube.com/embed/' + clip.embedCode,
        embedCode: clip.embedCode,
      };
      return clipReturn;
    });
  }

}
