import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-speaker-jsw2020',
  templateUrl: './speaker-jsw2020.component.html',
  styleUrls: ['./speaker-jsw2020.component.scss']
})
export class SpeakerJsw2020Component implements OnInit {

  constructor() { }

  public mainTitles = [
    'WŁĄCZENIE GŁOŚNIKA JBL I URUCHOMIENIE FUNKCJI BLUETOOTH',
    'URUCHOMIENIE FUNKCJI BLUETOOTH W URZĄDZENIU DOCELOWYM',
    'OD TEJ PORY GŁOŚNIK JBL BĘDZIE TRAKTOWANY JAKO URZĄDZENIE WYJŚCIOWE DLA DŹWIĘKU URZĄDZENIA',
  ]

  ngOnInit(): void {
  }

}
