import { NgModule } from '@angular/core';
import { SafePipe } from './pipes/safe-url.pipe';

@NgModule({
  declarations: [
    SafePipe,
  ],
  exports: [
    SafePipe,
  ]
})
export class SharingPipesModule {
}