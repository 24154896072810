import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-awards-jsw21',
  templateUrl: './awards-jsw21.component.html',
  styleUrls: ['./awards-jsw21.component.scss']
})
export class AwardsJsw21Component implements OnInit {

  constructor() { }

  public arrayWinnersArt = [
    { name: 'Martyna Kalus'},
    { name: 'Natalia Kalus'},
    { name: 'Aleksandra Olesińska'},
    { name: 'Alicja Olesińska'},
  ];


  public arrayWinnersPhoto = [
    { name: 'brak', text: 'brak', imagesSrc: [''] }
  ];

  ngOnInit(): void {
    this.initWinnersPhoto();
  }

  private initWinnersPhoto() {
    const path = '/assets/img/jsw2021/awards/contest-photo';
    const startNameImg = 'konkurs-fotograficzny-';
    const newArray = [];
    const arrayWinners = [
      { name: 'Łukasz Brzoza', text: 'Witamy u nas też małe piknikowanie balkonowe :) Pogoda nam nie straszna :) jak się bawić to się bawić. Pozdrawiamy organizatorów i uczestników.', pictureNumbers: [] },
      { name: 'Łukasz Jaszczyk', text: 'Na pikniku fajnie było,\nkwarantannę się odbyło,\npomysł taki przyszedł nam,\nby umilić czas i Wam:)', pictureNumbers: [1,2,3,4,5,6] },
      { name: 'Krzysztof Kalus', text: 'Piknik na ogrodzie, na przekór złej pogodzie.', pictureNumbers: [7,8] },
      { name: 'Adam Kocur', text: 'Pozdrawiamy z domówki', pictureNumbers: [] },
      { name: 'Mirosław Oleś', text: 'Piknik online? Jesteśmy zdecydowanie na tak! Kosz piknikowy został wypełniony wszystkim tym co niezbędne podczas ucztowania na świeżym powietrzu. Kolorowy koc dodał uroku do całości. Farbki, papier pozwoliły dzieciom kreatywnie się rozwijać, powstał statek i lisek, a dzięki głośnikowi mogliśmy słuchać muzyki i tańczyć :) Pozdrawiamy, Alicja i Mirosław Oleś', pictureNumbers: [9,10] },
      { name: 'Łukasz Pastryk', text: 'Nigdy by mi się nie śniło, że z rodziną na pikniku online w Chorwacji się było. Jblem muzyka leciała, kiełbaska upiekła się cała, z DJ tance hulance i Kopalnia nawet w wirtualny świat nas zabrała ! ', pictureNumbers: [] },
      // { name: '', text: '', pictureNumbers: [] },
    ];

    for (let i = 0; i < arrayWinners.length; i++) {
      const item = arrayWinners[i];
      const imagesSrcNew = item.pictureNumbers.map( itemImg => {
        let pathReturn = '';
        pathReturn = `${path}/${startNameImg}${itemImg}.jpg`;
        return pathReturn;
      });
      const winner = {
        name: item.name,
        text: item.text,
        imagesSrc: imagesSrcNew,
      }
      newArray.push(winner);
    };

    this.arrayWinnersPhoto = newArray;

  }

}
