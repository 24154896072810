import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Jsw2020RoutingModule } from './jsw2020-routing.module';
import { MainSiteJsw20Component } from './main-site-jsw20/main-site-jsw20.component';
import { AdviceJsw20Component } from './advice-jsw20/advice-jsw20.component';
import { AwardsJsw20Component } from './awards-jsw20/awards-jsw20.component';
import { ContestArtJsw20Component } from './contest-art-jsw20/contest-art-jsw20.component';
import { ContestPhotoJsw20Component } from './contest-photo-jsw20/contest-photo-jsw20.component';
import { CuriositiesJsw20Component } from './curiosities-jsw20/curiosities-jsw20.component';
import { LiveJsw20Component } from './live-jsw20/live-jsw20.component';
import { PrintingJsw20Component } from './printing-jsw20/printing-jsw20.component';
import { SecureJsw20Component } from './secure-jsw20/secure-jsw20.component';
import { TeamsJsw20Component } from './teams-jsw20/teams-jsw20.component';
import { GamesJsw2020Component } from './list-project-extras/games-jsw2020/games-jsw2020.component';
import { SpeakerJsw2020Component } from './list-project-extras/speaker-jsw2020/speaker-jsw2020.component';
import { Simulator3dJsw2020Component } from './list-project-extras/simulator3d-jsw2020/simulator3d-jsw2020.component';
import { SharingPipesModule } from '../../share/share.module';

@NgModule({
  declarations: [
    AdviceJsw20Component,
    AwardsJsw20Component,
    ContestArtJsw20Component,
    ContestPhotoJsw20Component,
    CuriositiesJsw20Component,
    LiveJsw20Component,
    MainSiteJsw20Component,
    PrintingJsw20Component,
    SecureJsw20Component,
    TeamsJsw20Component,
    GamesJsw2020Component,
    SpeakerJsw2020Component,
    Simulator3dJsw2020Component,
  ],
  providers: [
  ],
  imports: [CommonModule, FlexLayoutModule, SharingPipesModule, Jsw2020RoutingModule],
})
export class Jsw2020Module {}
