import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AwardsJsw21Component } from './awards-jsw21/awards-jsw21.component';
import { ContestArtJsw21Component } from './contest-art-jsw21/contest-art-jsw21.component';
import { ContestPhotoJsw21Component } from './contest-photo-jsw21/contest-photo-jsw21.component';
import { Jsw2021RoutingModule } from './jsw2021-routing.module';
import { LiveJsw21Component } from './live-jsw21/live-jsw21.component';
import { MainSiteJsw21Component } from './main-site-jsw21/main-site-jsw21.component';
import { PrintingJsw21Component } from './printing-jsw21/printing-jsw21.component';
import { SecureJsw21Component } from './secure-jsw21/secure-jsw21.component';
import { TeamsJsw21Component } from './teams-jsw21/teams-jsw21.component';
import { EducationGamesJsw2021Component } from './list-project-extras/education-games-jsw2020/education-games-jsw2021.component';
import { SharingPipesModule } from '../../share/share.module';


@NgModule({
  declarations: [
    // AdviceJsw21Component,
    AwardsJsw21Component,
    ContestArtJsw21Component,
    ContestPhotoJsw21Component,
    // CuriositiesJsw21Component,
    LiveJsw21Component,
    MainSiteJsw21Component,
    PrintingJsw21Component,
    SecureJsw21Component,
    TeamsJsw21Component,
    EducationGamesJsw2021Component,
  ],
  providers: [
  ],
  imports: [CommonModule, FlexLayoutModule, SharingPipesModule, Jsw2021RoutingModule],
})
export class Jsw2021Module {}
