import { Component, OnInit, OnDestroy } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
// import { Socket } from 'ngx-socket-io';

interface StreamModel {
  _id: string;
  name: string;
  link: string;
}

@Component({
  selector: 'app-live-jsw21',
  templateUrl: './live-jsw21.component.html',
  styleUrls: ['./live-jsw21.component.scss']
})
export class LiveJsw21Component implements OnInit, OnDestroy {

  constructor(
    // private http: HttpClient,
    // private socket: Socket,
  ) { }


  public kinderAnimYtClips = [
    { src: '', embedCode: 'Stwp1r21tOE'},
    { src: '', embedCode: 'HUmNMZREUfA'},
    { src: '', embedCode: '_ZN1GOfXPNU'},
    { src: '', embedCode: 'NXq49oG4j_o'},
    { src: '', embedCode: '-7B_drjq7A8'},
    { src: '', embedCode: 'p-ASLcBWyNI'},
  ];

  public listProjectExtras = [
    { text: 'Gry edukacyjne', link: '/jsw21/gry-edukacyjne'},
  ]

  public streamsArray: Array<StreamModel> = [
    {
      _id: '0id',
      name: 'Kanał animacji zabaw',
      link: 'https://www.youtube.com/embed/bPOy29IovtA'
    },
    {
      _id: '0id',
      name: 'Kanał DJ\'a',
      link: 'https://www.youtube.com/embed/3HObChowUlE'
    },
  ];

  ngOnInit(): void {
    // this.httpFetchStream();
    this.addKinderAnimYtClips();
    this.loadChatScript();
    // this.socket.on('updateStream', (item: StreamModel) => {
    //   if(item) {
    //     const index = this.streamsArray.findIndex( stream => stream._id === item._id);
    //     if (index+1) {
    //       this.streamsArray[index].name = item.name;
    //       this.streamsArray[index].link = item.link;
    //     }
    //   }
    // });
  }

  private addKinderAnimYtClips() {
    this.kinderAnimYtClips = this.kinderAnimYtClips.map( clip => {
      const clipReturn = {
        src: 'https://www.youtube.com/embed/' + clip.embedCode,
        embedCode: clip.embedCode,
      };
      return clipReturn;
    });
  }

  private loadChatScript() {
    document.querySelector('#cid0020000288364399013_')?.remove();
    document.querySelector('#cid0020000288364399013')?.remove();
    document.querySelector('#cid0020000288372574596_')?.remove();
    document.querySelector('#cid0020000288372574596')?.remove();
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;
    chatScript.src = "//st.chatango.com/js/gz/emb.js";
    chatScript.style.cssText = "width: 450px;height: 300px;";
    chatScript.id = "cid0020000288364399013";
    chatScript.textContent = `
    {"handle":"piknikonline-dartpol","arch":"js","styles":{"a":"ff9900","b":100,"c":"000000","d":"000000","k":"ff9900","l":"ff9900","m":"ff9900","p":"10","q":"ff9900","r":100,"pos":"br","cv":1,"cvbg":"ff9900","cvw":400,"cvh":30,"ticker":1,"fwtickm":1}}
    `;
    document.body.appendChild(chatScript);
    if (window.innerWidth < 900) {
      let dontTouchContainer = document.createElement("div");
      dontTouchContainer.className = "dontTouchContainer";
      document.body.appendChild(dontTouchContainer);
    }
  }

  // public httpFetchStream(): void {
  //   // this.http.get<StreamModel[]>('http://localhost:16300/jsw21/stream/get').subscribe( streams => {
  //   this.http.get<StreamModel[]>('http://146.59.45.80:16300/jsw21/stream/get').subscribe( streams => {
  //     this.streamsArray = streams.map( item => {
  //       const itemReturn: StreamModel = {
  //         _id: item._id,
  //         name: item.name,
  //         link: item.link,
  //       };
  //       return itemReturn;
  //     });
  //   });
  // }

  ngOnDestroy() {
    document.querySelector('body > iframe')?.remove();
    document.querySelector('#cid0020000288364399013_')?.remove();
    document.querySelector('#cid0020000288364399013')?.remove();
    document.querySelector('#cid0020000288372574596_')?.remove();
    document.querySelector('#cid0020000288372574596')?.remove();
  }

}
