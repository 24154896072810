<section class="main-section-jsw20" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">

  <h1>Poligrafia</h1>
  <p class="subtitles">Ulotki, plakaty są drukowane i projektowane przez firmę dart-pol na podstawie własnych szablonów</p>
  
  
  <div class="list-titlesNnumber-container-box">
    <div class="container-item" fxLayout="row wrap" fxLayoutAlign="start center">
      <div fxLayout="row" fxLayoutAlign="center center" class="number-div"><span>1</span></div>
      <p  class="text">ULOTKA (210MM X 160MM)</p>
      <p  class="text">PLAKAT (410MM X 320MM)</p>
    </div>
  </div>

  <div class="gallery-2-col" fxLayout="row wrap" fxLayoutAlign="center center" fxLayout.xs="column">
    <div class="container" fxLayout="column" fxLayoutAlign="start center">
      <div class="imgContainer" fxLayout="row" fxLayoutAlign="center center">
        <img  src="/assets/img/jsw2020/printing/ulotka-jsw-1.jpg" alt="ulotka-jsw-1.jpg">
      </div>
    </div>
    <div class="container" fxLayout="column" fxLayoutAlign="start center">
      <div class="imgContainer" fxLayout="row" fxLayoutAlign="center center">
        <img  src="/assets/img/jsw2020/printing/ulotka-jsw-2.jpg" alt="ulotka-jsw-2.jpg">
      </div>
    </div>
  </div>

</section>