import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  
  constructor(
    private authService: AuthService,
    private router: Router,
    ) { }
  
  private authListenerSubs: Subscription  = this.authService
    .getAuthStatusListener()
    .subscribe(isAuthenticated => {
      this.loginAuthenticated = isAuthenticated;
      const login = this.authService.getLogin();
      this.login = this.authService.getLogin();
      this.setSettingsOfLogin(isAuthenticated, login);
  });

  private loginAuthenticated = false;
  public mainMenuShowMobile = false;

  public dataFormAccess: FormGroup = new FormGroup({
    login: new FormControl('', { validators: [Validators.required] }),
  });

  public showLoginPanel = false;
  public access = {
    isLoading: false,
    logged: false,
    successError: false,
  };
  public login = '';

  public headerTopImgSrcActived = '/assets/img/header-logo.jpg';
  private headerTopImgSrcDefault = '/assets/img/header-logo.jpg';

  public routesLinksActived: Array<{link: string, text: string}> = [];

  ngOnInit(): void {
    this.loginAuthenticated = this.authService.getIsAuth();
    this.login = this.authService.getLogin();
    this.setSettingsOfLogin(this.loginAuthenticated, this.login);
    if (!this.loginAuthenticated) {
      this.router.navigate(['']);
    }
    // if (this.login === ''){
    //   this.onLogIn('dart-pol21');
    // }
  }

  public onToggleShowLoginPanel() {
    this.showLoginPanel = !this.showLoginPanel;
  }

  public onLogIn(login: string) {
    const success = this.authService.login(login, '');
    if(!success) {
      this.access.successError = true;
    } else {
      this.access.successError = false;
    }
    
  }
  public onLogout() {
    this.authService.clearAuthData();
    this.login = '';
    this.showLoginPanel = false;
    this.access.logged = false;
    this.setDefault();
    this.authService.logout();
    this.router.navigate(['']);
  }

  private setDefault() {
    this.routesLinksActived = [];
    this.headerTopImgSrcActived = this.headerTopImgSrcDefault;
  }

  private setSettingsOfLogin(isAuthenticated: boolean, login: string) {
    this.login = login;
    if (isAuthenticated) {
      this.routesLinksActived = this.authService.getRoutesLinksActived();
      this.headerTopImgSrcActived = this.authService.getHeaderTopImgSrcActived();
      this.access.logged = true;
      this.showLoginPanel = false;
    } else {
      this.login = '';
      this.showLoginPanel = false;
      this.access.logged = false;
      this.setDefault();
    }
  }

  public onSubmitAccess() {
    if (!this.dataFormAccess.invalid) {
      const login: string = this.dataFormAccess.value.login;
      this.onLogIn(login);
    }
  }

  public onToggleMainMenu() {
    let menuContainer = document.getElementById('menuContainerId');
    if (menuContainer == null) {
      return;
    }
    let heightMenu = menuContainer.offsetHeight;
    if (this.mainMenuShowMobile) {
      menuContainer.style.height = 'inherit';
      document.getElementById('menuBoxId')?.classList.remove('stickyMobile');
      document.getElementById('toggle-menu-icon')?.classList.remove('open');
    } else {
      menuContainer.style.height = heightMenu + 'px';
      document.getElementById('menuBoxId')?.classList.add('stickyMobile');
      document.getElementById('toggle-menu-icon')?.classList.add('open');
    }
    this.mainMenuShowMobile = !this.mainMenuShowMobile;
  }
  public onGoToTop() {
    console.log('onGoToTop()');
  }

  // window.onscroll = function() {scrollFunction()};

  // function scrollFunction() {
  //   if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
  //     document.getElementById("navbar").style.padding = "30px 10px";
  //     document.getElementById("logo").style.fontSize = "25px";
  //   } else {
  //     document.getElementById("navbar").style.padding = "80px 10px";
  //     document.getElementById("logo").style.fontSize = "35px";
  //   }
  // }
  // https://css-tricks.com/how-to-make-an-unobtrusive-scroll-to-top-button/

  public ngOnDestroy() {
    this.authListenerSubs.unsubscribe();
  }
}
