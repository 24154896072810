import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-simulator3d-jsw2020',
  templateUrl: './simulator3d-jsw2020.component.html',
  styleUrls: ['./simulator3d-jsw2020.component.scss']
})
export class Simulator3dJsw2020Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
