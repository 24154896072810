import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-printing-jsw21',
  templateUrl: './printing-jsw21.component.html',
  styleUrls: ['./printing-jsw21.component.scss']
})
export class PrintingJsw21Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
