import { Component } from '@angular/core';

@Component({
  selector: 'app-main-site-jsw21',
  templateUrl: './main-site-jsw21.component.html',
  styleUrls: ['./main-site-jsw21.component.scss']
})
export class MainSiteJsw21Component {
  constructor() { }
}
