import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
// import { TestsBuildElementsComponent } from './tests-build-elements/tests-build-elements.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  // { path: 'test', component: TestsBuildElementsComponent }, // to remove/comment
  { path: 'jsw20', loadChildren: () => import('./main/jsw2020/jsw2020.module').then(module => module.Jsw2020Module)},
  { path: 'jsw21', loadChildren: () => import('./main/jsw2021/jsw2021.module').then(module => module.Jsw2021Module)},
  // { path: 'budowa', loadChildren: () => import('./main/test1/test1.module').then(module => module.Test1Module)},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
