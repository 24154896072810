<section class="jsw2021 sectionPadding section1" fxLayout="column" fxLayoutAlign="center center">
  <div class="twoDivInRow" fxLayout="row wrap" fxLayoutAlign="space-between center">
    <div class="text-container" fxLayout="column" fxLayoutAlign="start center">
      <h1 class="title1">Piknik Online</h1>
      <p>Relacja na żywo, chat online, filmy instruktażowe</p>
    </div>
    <div class="img-container" fxLayout="row" fxLayoutAlign="center center">
      <img src="/assets/img/jsw2020/logo-jsw-2020.png" alt="logo-jsw-2020">
    </div>
  </div>
</section>

<section class="jsw2021" fxLayout="row wrap" fxLayoutAlign="space-between start">
  <div class="section-text" fxLayout="column" fxLayoutAlign="center start">
    <section class="sectionPadding" fxLayout="column" fxLayoutAlign="start center">
      <h1 class="title2">Relacja na żywo - 2 kanały</h1>
    </section>

    <div class="chanels-container sectionPadding" fxLayout="row wrap" fxLayoutAlign="start center" fxLayout.xs="column" fxLayoutGap="10px">
      <div class="chanel-container">
        <div class="titlesNnumber-container-box">
          <div class="container-item" fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="row" fxLayoutAlign="center center" class="number-div"><span>1</span></div>
            <h2 class="text">{{streamsArray[0].name}}</h2>
          </div>
        </div>
        <div class="iframe-yt" fxLayout="row" fxLayoutAlign="center center">
          <iframe width="100%" height="100%" [src]="streamsArray[0].link | safeUrl" frameborder="0" allowfullscreen="true"></iframe>
        </div>
      </div>
      <div class="chanel-container">
        <div class="titlesNnumber-container-box">
          <div class="container-item" fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="row" fxLayoutAlign="center center" class="number-div"><span>2</span></div>
            <h2 class="text">{{streamsArray[1].name}}</h2>
          </div>
        </div>
        <div class="iframe-yt" fxLayout="row" fxLayoutAlign="center center">
          <iframe width="100%" height="100%" [src]="streamsArray[1].link | safeUrl" frameborder="0" allowfullscreen="true"></iframe>
        </div>
      </div>
    </div>
  </div>
</section>


<section class="jsw2021 sectionPadding" fxLayout="column" fxLayoutAlign="start center">
  <h1 class="title1">Warsztaty szachowe</h1>
  <p>Samouczek szachowy: Nauka gry w szachy od podstaw</p>
  <div class="yt-cointainer-1" fxLayout="row wrap" fxLayoutAlign="center center">
    <div class="iframe-yt-container" fxLayout="row" fxLayoutAlign="center center">
      <iframe width="100%" height="100%" src="https://www.youtube.com/embed/9t6IAPWkRdM" frameborder="0" allowfullscreen="true"></iframe>
    </div>
  </div>
</section>


<section class="jsw2021" fxLayout="row wrap" fxLayoutAlign="space-between start">
  <div class="section-text" fxLayout="column" fxLayoutAlign="center start">
    <section class="sectionPadding" fxLayout="column" fxLayoutAlign="start center">
      <h1 class="title2">Zapraszamy na chat</h1>
      <p>Prosimy kliknąć w prawy dolny róg czatu 'Wybierz nazwę użytkownika' oraz logowanie 'Z tymczasową nazwą'</p>
      <div class="chat-cointainer" fxLayout="row wrap" fxLayoutAlign="center center">
        <iframe frameborder="0" allow="autoplay" src="https://piknikonline-dartpol.chatango.com"></iframe>
      </div>
      <!-- <a class="chatango-link" href="https://piknikonline-dartpol.chatango.com/" target="_blank">Link do chatu - piknikonline-dartpol.chatango.com</a> -->
    </section>
  </div>
</section>


<section class="jsw2021" fxLayout="row wrap" fxLayoutAlign="space-between start">
  <section class="sectionPadding" fxLayout="column" fxLayoutAlign="start center">
    <h1 class="title2">Filmy z animacją zabaw</h1>
    <p>Filmy instruktażowe z animacją zabaw dla dzieci</p>
  </section>
  <div class="kinder-anim-yt-cointainer" fxLayout="row wrap" fxLayoutAlign="center center">
    <div *ngFor="let item of kinderAnimYtClips" class="iframe-yt-container" fxLayout="row" fxLayoutAlign="center center">
      <iframe width="100%" height="100%" [src]="item.src | safeUrl" frameborder="0" allowfullscreen="true"></iframe>
    </div>
  </div>
</section>


<section class="jsw2021" fxLayout="row wrap" fxLayoutAlign="space-between start">
  <div class="section-text" fxLayout="column" fxLayoutAlign="center start">
    <section class="sectionPadding" fxLayout="column" fxLayoutAlign="start center">
      <h1 class="title2">Dodatki do projektu Piknik Online</h1>
      <p>Konkursy, gry edukacyjne, instrukcje, filmy</p>
    </section>
    <div class="list-links-ol-box">
      <a *ngFor="let item of listProjectExtras; let i = index" [routerLink]="item.link" target="_blank" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
        <div fxLayout="row" fxLayoutAlign="center center" class="number-div"><span>{{i+1}}</span></div>
        <p  class="text">{{item.text}}</p>
      </a>
    </div>
  </div>
</section>


<section class="jsw2021" fxLayout="row wrap" fxLayoutAlign="space-between start">
  <section class="sectionPadding" fxLayout="column" fxLayoutAlign="start center">
    <h1 class="title2">Filmy z origami</h1>
    <p>Filmy instruktażowe z origami</p>
  </section>
  <div class="yt-clips-50-cointainer" fxLayout="row wrap" fxLayoutAlign="center center">
    <div class="iframe-yt-container" fxLayout="row" fxLayoutAlign="center center">
      <iframe width="100%" height="100%" src="https://www.youtube.com/embed/jUbLro9GpfQ" frameborder="0" allowfullscreen="true"></iframe>
    </div>
    <div class="iframe-yt-container" fxLayout="row" fxLayoutAlign="center center">
      <iframe width="100%" height="100%" src="https://www.youtube.com/embed/jLuxdErH9fc" frameborder="0" allowfullscreen="true"></iframe>
    </div>
  </div>

  <section class="sectionPadding" fxLayout="column" fxLayoutAlign="start center">
    <h1 class="title2">Dj Acrox</h1>
    <p>Gwiazda Pikniku Online</p>
  </section>
  <div class="yt-clips-50-cointainer" fxLayout="row wrap" fxLayoutAlign="center center">
    <div class="iframe-yt-container" fxLayout="row" fxLayoutAlign="center center">
      <iframe width="100%" height="100%" src="https://www.youtube.com/embed/iXj48kZetIo" frameborder="0" allowfullscreen="true"></iframe>
    </div>
    <div class="iframe-yt-container" fxLayout="row" fxLayoutAlign="center center">
      <iframe width="100%" height="100%" src="https://www.youtube.com/embed/9NiWz9VUQWY" frameborder="0" allowfullscreen="true"></iframe>
    </div>
  </div>

</section>


<section class="sectionPadding">
  <p id="gray-info">Przedstawione materiały stanowią własność intelektualną firmy, zabrania się kopiowania, publikowania i wykorzystywania przedstawionych treści bez zgody właściciela filmy dart-pol.</p>
</section>
