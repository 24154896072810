import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AwardsJsw21Component } from './awards-jsw21/awards-jsw21.component';
import { ContestArtJsw21Component } from './contest-art-jsw21/contest-art-jsw21.component';
import { ContestPhotoJsw21Component } from './contest-photo-jsw21/contest-photo-jsw21.component';
import { EducationGamesJsw2021Component } from './list-project-extras/education-games-jsw2020/education-games-jsw2021.component';
import { LiveJsw21Component } from './live-jsw21/live-jsw21.component';
import { MainSiteJsw21Component } from './main-site-jsw21/main-site-jsw21.component';
import { PrintingJsw21Component } from './printing-jsw21/printing-jsw21.component';
import { SecureJsw21Component } from './secure-jsw21/secure-jsw21.component';
import { TeamsJsw21Component } from './teams-jsw21/teams-jsw21.component';


const jsw2021Routes: Routes = [
  { path: 'strona-glowna', component: MainSiteJsw21Component },
  { path: 'bezpiecznstwo', component: SecureJsw21Component },
  { path: 'relacja-na-zywo', component: LiveJsw21Component },
  { path: 'konkurs-fotograficzny', component: ContestPhotoJsw21Component },
  { path: 'konkurs-plastyczny', component: ContestArtJsw21Component },
  { path: 'pokoj-teams', component: TeamsJsw21Component },
  { path: 'poligrafia', component: PrintingJsw21Component },
  { path: 'nagrody', component: AwardsJsw21Component },
  // { path: 'porady', component: AdviceJsw21Component },
  // { path: 'ciekawostki', component: CuriositiesJsw21Component },

  { path: 'gry-edukacyjne', component: EducationGamesJsw2021Component },
];

@NgModule({
  imports: [RouterModule.forChild(jsw2021Routes)],
  exports: [RouterModule],
})
export class Jsw2021RoutingModule {}
