<section class="main-section-jsw20" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
  <h1>Gry edukacyjne</h1>
  <p class="subtitle">Zapraszamy do zapoznania się z grami dla najmłodszych</p>
  <div class="game-cointainer" fxLayout="row wrap" fxLayoutAlign="center center">
    <iframe frameborder="0" allowfullscreen="true" src="https://wordwall.net/pl/embed/8112e9a486e24259acff4877c577eb1a?themeId=49&templateId=45"></iframe>
  </div>
  <div class="game-cointainer" fxLayout="row wrap" fxLayoutAlign="center center">
    <iframe frameborder="0" allowfullscreen="true" src="https://wordwall.net/pl/embed/40bba220bfbf4ff298ade0308312d033?themeId=2&templateId=10"></iframe>
  </div>
  <div class="game-cointainer" fxLayout="row wrap" fxLayoutAlign="center center">
    <iframe frameborder="0" allowfullscreen="true" src="https://wordwall.net/pl/embed/0adfc6766c0345e08ba5d01eda73ae5f?themeId=1&templateId=36"></iframe>
  </div>
</section>