import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class AuthService {
  private isAuthenticated = false;
  private loginPrivate: string = '';
  public authStatusListener = new Subject<boolean>();

  private headerTopImgSrcActived = '/assets/img/header-logo.jpg';
  private routesLinksActived: Array<{link: string, text: string}> = [];
  private dataPage: Array<{
    token: string,
    login: string,
    pass: string,
    headerTopImgSrc: string,
    routeLink: string,
    routesLinks: Array<{link: string, text: string}>,
  }> = [
    {
      token: '.t}e<w9>boGy:H[5y3M,<-{Dgg^j6}J_',
      login: 'jsw2020',
      pass: 'dart-pol99',
      headerTopImgSrc: '/assets/img/jsw2020/header-logo.jpg',
      routeLink: 'jsw20',
      routesLinks: [
        { link: '/jsw20/strona-glowna', text: 'Strona Główna'},
        { link: '/jsw20/bezpiecznstwo', text: 'Bezpieczeństwo'},
        { link: '/jsw20/relacja-na-zywo', text: 'Piknik Online - Relacja na żywo'},
        { link: '/jsw20/konkurs-fotograficzny', text: 'Konkurs fotograficzny'},
        { link: '/jsw20/konkurs-plastyczny', text: 'Konkurs plastyczny'},
        { link: '/jsw20/pokoj-teams', text: 'Pokój teams'},
        { link: '/jsw20/poligrafia', text: 'Poligrafia'},
        { link: '/jsw20/nagrody', text: 'Nagrody'},
        { link: '/jsw20/porady', text: 'Porady'},
        { link: '/jsw20/ciekawostki', text: 'Ciekawostki'},
      ],
    },
    {
      token: '-fF)@%=dL;ow4ot9B+&]+h+:FbcNh,!2',
      login: 'jsw2021',
      pass: 'dart-pol21',
      headerTopImgSrc: '/assets/img/jsw2021/piknikonline-logo-header.jpg',
      routeLink: 'jsw21',
      routesLinks: [
        { link: '/jsw21/strona-glowna', text: 'Strona Główna'},
        { link: '/jsw21/bezpiecznstwo', text: 'Bezpieczeństwo'},
        { link: '/jsw21/relacja-na-zywo', text: 'Piknik Online - Relacja na żywo'},
        { link: '/jsw21/konkurs-fotograficzny', text: 'Konkurs fotograficzny'},
        { link: '/jsw21/konkurs-plastyczny', text: 'Konkurs plastyczny'},
        { link: '/jsw21/pokoj-teams', text: 'Pokój teams'},
        { link: '/jsw21/poligrafia', text: 'Poligrafia'},
        { link: '/jsw21/nagrody', text: 'Nagrody'},
        // { link: '/jsw21/porady', text: 'Porady'},
        // { link: '/jsw21/ciekawostki', text: 'Ciekawostki'},
      ],
    },
    // {
    //   token: '.9^V>%PQD_ofxSn9Wk]fsa.<s(H;vsxy',
    //   login: 'test',
    //   pass: 'dart-pol21',
    //   headerTopImgSrc: '/assets/img/jsw2021/piknikonline-logo-header.jpg',
    //   routeLink: 'budowa',
    //   routesLinks: [
    //     { link: '/budowa/strona-glowna', text: 'Strona Główna'},
    //   ],
    // },
  ];

  constructor(
    private router: Router,
    private location: Location,
  ) {
    const authData = this.getAuthData();
    if (authData.login){
      this.login(authData.login, authData.token);
    }
  }


  public getIsAuth() {
    return this.isAuthenticated;
  }

  public getLogin() {
    return this.loginPrivate;
  }

  public getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  public getRoutesLinksActived() {
    return this.routesLinksActived.slice();
  }
  public getHeaderTopImgSrcActived() {
    return this.headerTopImgSrcActived;
  }

  public login(pass: string | null, token: string | null): boolean {
    let success: boolean = false;
    const urlIndex = this.location.path().indexOf('/', 1);
    const routeLink = this.location.path().slice(1, urlIndex);
    if (pass){
      const password = pass.charAt(0).toLowerCase() + pass.slice(1);
      const dataPage = this.dataPage.find( item => item.pass === password);
      if(token){
        if(token !== dataPage?.token){
          return success;
        }
      }
      if(dataPage) {
        success = true;
        this.loginPrivate = dataPage.pass;
        this.routesLinksActived = [...dataPage.routesLinks];
        this.headerTopImgSrcActived = dataPage.headerTopImgSrc;
        this.saveAuthData(dataPage.token, dataPage.pass);
        if(!this.isAuthenticated) {
          this.isAuthenticated = true;
          this.authStatusListener.next(true);
          if(routeLink !== dataPage.routeLink) {
            this.router.navigate([dataPage.routesLinks[0].link]);
          }
        }
        this.isAuthenticated = true;
        this.authStatusListener.next(true);
      }
    }
    return success;
  }

  public logout() {
    this.clearAuthData();
    this.loginPrivate = '';
    this.isAuthenticated = false;
    this.routesLinksActived = [];
    this.headerTopImgSrcActived = '';
    // this.authStatusListener.next(false);
    // this.router.navigate(['']);
  }

  private saveAuthData(token: string, login: string) {
    localStorage.setItem('token', token);
    localStorage.setItem('login', login);
  }

  public clearAuthData() {
    localStorage.removeItem('token');
    localStorage.removeItem('login');
  }

  private getAuthData() {
    const token: string | null = localStorage.getItem('token');
    const login: string | null = localStorage.getItem('login');
    return {
      token,
      login,
    };
  }
}
