import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-games-jsw2020',
  templateUrl: './games-jsw2020.component.html',
  styleUrls: ['./games-jsw2020.component.scss']
})
export class GamesJsw2020Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
