import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }

  public imagesList = [
    {number: 1, path: ''},
    {number: 2, path: ''},
    {number: 3, path: ''},
  ];

  ngOnInit(): void {
    this.initImagesList();
  }

  private initImagesList() {
    this.imagesList = this.imagesList.map( image => {
      const returnImage = {
        number: image.number,
        path: `/assets/img/home/piknik-${image.number}.jpg`,
      };
      return returnImage;
    });
  };

}
