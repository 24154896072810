<section class="jsw2021 sectionPadding" fxLayout="column" fxLayoutAlign="start center">
  <h1 class="title1">Poligrafia</h1>
  <p>Ulotki, plakaty są drukowane i projektowane przez firmę dart-pol na podstawie własnych szablonów</p>
</section>

<section class="jsw2021" fxLayout="row wrap" fxLayoutAlign="space-between start">
  <div class="section-text" fxLayout="column" fxLayoutAlign="center start">
    <div class="text-container" fxLayout="column" fxLayoutAlign="center start">

      <div class="titlesNnumber-container-box">
        <div class="container-item" fxLayout="column" fxLayoutAlign="center start" fxLayoutAlign.xs="center center">
          <div fxLayout="row" fxLayoutAlign="center center" class="number-div"><span>1</span></div>
          <h2 class="text">ULOTKA</h2>
          <h2 class="text">PLAKAT</h2>
        </div>
      </div>

      <div class="gallery-2-col" fxLayout="row wrap" fxLayoutAlign="center center" fxLayout.xs="column">
        <div class="container" fxLayout="column" fxLayoutAlign="start center">
          <div class="imgContainer" fxLayout="row" fxLayoutAlign="center center">
            <img  src="/assets/img/jsw2021/printing/ulotka-jsw-1.jpg" alt="ulotka-jsw-1.jpg">
          </div>
        </div>
        <div class="container" fxLayout="column" fxLayoutAlign="start center">
          <div class="imgContainer" fxLayout="row" fxLayoutAlign="center center">
            <img  src="/assets/img/jsw2021/printing/ulotka-jsw-2.jpg" alt="ulotka-jsw-2.jpg">
          </div>
        </div>
      </div>

    </div>
  </div>
</section>

<section class="sectionPadding">
  <p id="gray-info">Przedstawione materiały stanowią własność intelektualną firmy, zabrania się kopiowania, publikowania i wykorzystywania przedstawionych treści bez zgody właściciela filmy dart-pol.</p>
</section>