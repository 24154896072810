import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { TestsBuildElementsComponent } from './tests-build-elements/tests-build-elements.component';
import { Jsw2020Module } from './main/jsw2020/jsw2020.module';
import { Jsw2021Module } from './main/jsw2021/jsw2021.module';
import { HttpClientModule } from '@angular/common/http';
// import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';

// const socketIoConfig: SocketIoConfig = {
//   url: 'http://146.59.45.80:16300',
//   // url: 'http://localhost:16300',
//   options: {},
// };

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    HeaderComponent,

    // TestsBuildElementsComponent, // to remove/comment
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    Jsw2020Module,
    Jsw2021Module,
    // SocketIoModule.forRoot(socketIoConfig),
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
