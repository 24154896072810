<section class="main-section-jsw20" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
  <h1>KONKURS PLASTYCZNY!</h1>
  <p class="subtitle">Z myślą o najmłodszych uczestnikach pikniku ogłaszamy konkurs plastyczny. Poprosimy dzieci, by narysowały jak spędzają czas ze swoją rodziną podczas naszego pikniku. Gotowe prace wraz z oświadczeniem dot. przystąpienia do konkursu należy wysłać na adres pocztowy: DART-POL s.c., ul. Sznelowiec 10, 43-200 Pszczyna. Z nadesłanych prac stworzymy niepowtarzalną galerię „Pikniku online”, gdzie wszystkie rodziny będą już obok siebie, a naszych milusińskich, od których otrzymamy takie prace nagrodzimy pluszakami lub spinnerami.</p>

  <div class="big-buttons" fxLayout="row wrap" fxLayoutAlign="center center">
    <a class="button" target="_blank" href="/assets/pdf/jsw2020/regulamin-konkursu-plastycznego.pdf">REGULAMIN KONKURSU PLASTYCZNEGO</a>
    <a class="button" target="_blank" href="/assets/pdf/jsw2020/oswiadczenie-do-udzialu-w-konkursie.pdf">OŚWIADCZENIE do udziału w konkursie</a>
  </div>

  <div class="gallery" fxLayout="row wrap" fxLayoutAlign="center center" fxLayout.xs="column">
    <div *ngFor="let item of galleryArray" class="container" fxLayout="column" fxLayoutAlign="start center">
      <h2>{{item.author}}</h2>
      <div class="imgContainer" fxLayout="row" fxLayoutAlign="center center">
        <img  [src]="item.src" [alt]="item.alt">
      </div>
    </div>
  </div>

  <p id="gray-info">Przedstawione materiały stanowią własność intelektualną firmy, zabrania się kopiowania, publikowania i wykorzystywania przedstawionych treści bez zgody właściciela filmy dart-pol.</p>
</section>