import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contest-photo-jsw21',
  templateUrl: './contest-photo-jsw21.component.html',
  styleUrls: ['./contest-photo-jsw21.component.scss']
})
export class ContestPhotoJsw21Component implements OnInit {

  constructor() { }
  // dodać pobranie regulaminu - pdf

  public galleryArray = [
    { src: 'brak', alt: 'brak-zdjecia'},
  ];

  ngOnInit(): void {
    this.initImagesToGallery();
  }

  private initImagesToGallery() {
    // konkurs-1.jpg
    const newGallery = [];
    const numberOfImages = 9; // liczone od 1
    const startNameImge = 'konkurs-';
    const path = '/assets/img/jsw2020/contest-photo';
    for (let i = 1; i <= numberOfImages; i++) {
      const img = {
        src: `${path}/${startNameImge}${i}.jpg`,
        alt: `${startNameImge}${i}`,
      }
      newGallery.push(img);
    }
    this.galleryArray = newGallery;
  }

}
