<section class="main-section-jsw20" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
  <h1>CIEKAWOSTKI</h1>
    
  <div class="list-titlesNnumber-container-box">
    <div class="container-item" fxLayout="row" fxLayoutAlign="start center">
      <div fxLayout="row" fxLayoutAlign="center center" class="number-div"><span>1</span></div>
      <p  class="text">Ciekawostki realizacyjne</p>
    </div>
  </div>

  <ul>
    <hr>
    <li>W rolę przykładowej rodziny wcieliły się znane medialnie osoby. Wspaniały wieloletni aktor Teatru z Bielska-Białej Pan Grzegorz Sikora oraz prezenterka Radio Bielsko Agnieszka Płoskonka. Rolę młodych aktorów odegrały dzieci właściciela firmy Daria i Oskar Myśliwiec. Realizacja pikniku online odbyła się w trzech studiach nagraniowych, specjalnie na tę okazję został wymyślony nowy program animacji zabaw.</li>
    <hr>
    <li>Gwiazdą pikniku online był Dj Arcox. Jako ciekawostkę możemy powiedzieć, że Ojciec tego znanego, obecnie pracującego w Warszawie Dj'a jest emerytowanym górnikiem, który pracował na stanowisku sztygara w kopalni JSW Pniówek.</li>
    <hr>
    <li>W związku z czym realizacja piknik online dla Jastrzębskiej Spółki Węglowej dała dużo osobistej satysfakcji dj'owi. Łączny materiał wideo Nagrany na potrzeby pikniku online wyniósł 40 godzin nagraniowych, a łączna ilość obsługi zaangażowej w realicję to 40 osób.</li>
    <hr>
  </ul>

  <p id="gray-info">Przedstawione materiały stanowią własność intelektualną firmy, zabrania się kopiowania, publikowania i wykorzystywania przedstawionych treści bez zgody właściciela filmy dart-pol.</p>

</section>