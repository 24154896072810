import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }

  public footerContactData = [
    {
      text: {
        line1: 'Dart-Pol Sp. z o.o.',
        line2: '43-200 Pszczyna, ul. Sznelowiec 10',
      },
      icon: {
        src: '/assets/img/icons/place-location.svg',
        alt: 'lokalizacja',
      },
    },
    {
      text: {
        line1: '0 605 264 638',
        line2: 'Marcin Myśliwiec',
      },
      icon: {
        src: '/assets/img/icons/call-phone-footer.svg',
        alt: 'telefon',
      },
    },
    {
      text: {
        line1: 'imprezy@dart-pol.pl',
        line2: '',
      },
      icon: {
        src: '/assets/img/icons/email-contact.svg',
        alt: 'poczta',
      },
    },
    {
      text: {
        line1: 'Pn-Pt 9:00 - 17:00',
        line2: '',
      },
      icon: {
        src: '/assets/img/icons/schedule-time.svg',
        alt: 'czas',
      },
    },
  ];

  public footerWebsitesData = [
    { href: 'https://dart-pol.pl/', text: 'www.dart-pol.pl' },
    { href: 'http://balmikolajkowy.pl/', text: 'www.balmikolajkowy.pl' },
    { href: 'http://organizacja-imprez.eu/', text: 'www.organizacja-imprez.eu' },
    { href: 'http://imprezy-firmowe.eu/', text: 'www.imprezy-firmowe.eu' },
    { href: 'http://kasyno-wynajem.pl/', text: 'www.kasyno-wynajem.pl' },
    { href: 'http://dmuchance.info.pl/', text: 'www.dmuchance.info.pl' },
    { href: 'http://dart-pol.com.pl/', text: 'www.dart-pol.com.pl' },
  ];

  public year = 2021;

  ngOnInit(): void {
    const newDate = new Date();
    this.year = newDate.getFullYear();
  }

}
