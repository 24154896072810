import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-site-jsw20',
  templateUrl: './main-site-jsw20.component.html',
  styleUrls: ['./main-site-jsw20.component.scss']
})
export class MainSiteJsw20Component implements OnInit {

  constructor() { }

  public accessoryList = [
    {number: 1, text: 'Zapalniczka i rozpałka do grilla'},
    {number: 2, text: 'Naczynia i sztućce plastikowe lub metalowe'},
    {number: 3, text: 'Produkty spożywcze w tym produkty na grill kiełbasa, szaszłyk'},
    {number: 4, text: 'Woda do zalania, wygaszenia grilla'},
    {number: 5, text: 'Serwetki'},
    {number: 6, text: 'Worek na śmieci'},
    {number: 7, text: 'Chusteczki nawilżające do zmycia farby z twarzy po warsztatach malowania'},
  ];

  ngOnInit(): void {
  }

}
