
<section class="jsw2021 sectionPadding" fxLayout="column" fxLayoutAlign="start center">
  <h1 class="title1">Konkurs Plastyczny</h1>
</section>


<section class="jsw2021" fxLayout="row wrap" fxLayoutAlign="space-between start">
  <div class="section-text" fxLayout="column" fxLayoutAlign="center start">
    <div class="text-container" fxLayout="column" fxLayoutAlign="center start">
      <h3 class="title2">„ZABAWA NA PIKNIKU”</h3>
      <p>{{'&emsp;'}} Z myślą o najmłodszych uczestnikach pikniku przygotowaliśmy konkurs plastyczny pn. „Zabawa na pikniku”.</p>
      <p>{{'&emsp;'}} Poprosimy dzieci, by narysowały jak spędzają czas ze swoją rodziną podczas naszego Pikniku Online. Gotowe prace wraz z oświadczeniem dot. przystąpienia do konkursu należy wysłać na adres pocztowy: DART-POL Sp. z o.o., ul. Sznelowiec 10, 43-200 Pszczyna.</p>
      <p>{{'&emsp;'}} Z nadesłanych prac stworzymy niepowtarzalną galerię Pikniku Online, a naszych milusińskich, od których otrzymamy takie prace nagrodzimy pluszakami. Nagrodzonych zostanie pierwsze 10 osób.</p>
    </div>
    <div class="big-buttons" fxLayout="row wrap" fxLayoutAlign="center center">
      <a class="button" target="_blank" href="/assets/pdf/jsw2021/regulamin-konkursu-plastycznego.pdf">REGULAMIN KONKURSU PLASTYCZNEGO</a>
      <a class="button" target="_blank" href="/assets/pdf/jsw2021/oswiadczenie-do-udzialu-w-konkursie.pdf">OŚWIADCZENIE do udziału w konkursie</a>
    </div>
  </div>
</section>
